import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { modalStyles } from './styles'
import { TextField } from '@mui/material'
import LoadingIndicator from '../../common/LoadingIndicator'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'

const NewsPopup = () => {
  const { loading, data } = useFetch(`${urlApi}/admin/broadcast/news-popups`)
  const [activePopups, setActivePopups] = useState([])
  const [inactivePopups, setInactivePopups] = useState([])
  const [createModal, setCreateModal] = useState(false)

  useEffect(() => {
    if (!loading && data?.length > 0) {
      setActivePopups(data.filter(popup => popup.active))
      setInactivePopups(data.filter(popup => !popup.active))
    }
  }, [loading, data])

  return (
    <>
      {loading ? (
        <div className='w-full h-screen flex items-center justify-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='flex flex-row justify-between w-full h-full p-10'>
          <div className='h-[90vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <div className='flex gap-6 mb-4 items-center'>
              <p className='text-xl font-bold'>Popups activos</p>
              <p
                className='text-lg text-light_coral cursor-pointer'
                onClick={() => setCreateModal(true)}
              >
                Crear popup
              </p>
            </div>
            {activePopups.length === 0 ? (
              <p>No hay popups activos</p>
            ) : (
              activePopups.map(popup => <PopupCard popup={popup} />)
            )}
          </div>
          <div className='h-[90vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <p className='text-xl font-bold mb-4'>Chats desactivados</p>
            {inactivePopups.length === 0 ? (
              <p>No hay popups desactivados</p>
            ) : (
              inactivePopups.map(popup => <PopupCard popup={popup} />)
            )}
          </div>
        </div>
      )}
      {createModal && (
        <CreateModal setActivePopups={setActivePopups} setCreateModal={setCreateModal} />
      )}
    </>
  )
}

const PopupCard = ({ popup }) => {
  const [active, setActive] = useState(popup.active)
  const [update, setUpdate] = useState(false)
  const date = new Date(popup.date).toLocaleDateString()

  const handleToggle = () => {
    if (window.confirm('¿Activar/Desactivar popup?')) {
      setUpdate(true)
    }
  }

  return (
    <div className='w-full rounded-[12px] flex flex-col p-6 bg-white shadow-xl gap-8 mb-3'>
      <div className='flex justify-between items-center w-full'>
        <p className='max-w-2/3'>{popup.name}</p>
        <p className='font-bold'>{date}</p>
      </div>
      <div className='flex flex-row w-full justify-between'>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Enviado</p>
          <p>{popup.sent_to}</p>
        </div>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Leido</p>
          <p>{popup.seen_by}</p>
        </div>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Open Rate</p>
          <p>{((popup.seen_by / popup.sent_to) * 100).toFixed(2)}%</p>
        </div>
      </div>
      <div className='w-full flex items-end justify-between'>
        {popup.cohort_id ? <p className='font-bold'>Cohort Id: {popup.cohort_id}</p> : <div />}
        {update ? (
          <Update id={popup._id} active={active} setUpdate={setUpdate} setActive={setActive} />
        ) : (
          <ToggleSwitch checked={active} handleToggle={handleToggle} />
        )}
      </div>
    </div>
  )
}

const ToggleSwitch = ({ checked, handleToggle }) => {
  return (
    <label className={'relative inline-flex items-center cursor-pointer'}>
      <div
        onClick={handleToggle}
        className={`ring-0 rounded-full ${
          checked ? 'bg-light_coral after:bg-white after:translate-x-[19px]' : 'bg-[#E8E8E8]'
        }  after:bg-[#ABABAB] outline-none duration-300 after:duration-300 w-[50px] h-[30px] shadow-md peer-focus:outline-none after:rounded-full after:absolute after:outline-none after:h-[20px] after:w-[20px] after:top-[5px] after:left-[6px] after:flex after:justify-center after:items-center peer-hover:after:scale-95 after:content-[""]`}
      />
    </label>
  )
}

const CreateModal = ({ setActivePopups, setCreateModal }) => {
  const [name, setName] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [url, setUrl] = useState('')
  const [image, setImage] = useState('')
  const [cohortId, setCohortId] = useState()
  const [create, setCreate] = useState(false)

  const handleClose = () => {
    setCreateModal(false)
  }

  const handleSuccess = data => {
    setActivePopups(prev => [data, ...prev])
    setCreate(false)
    setCreateModal(false)
  }

  const handleError = () => {
    alert('Hubo un error')
    setCreate(false)
  }

  return (
    <Modal style={modalStyles} isOpen={true} onRequestClose={handleClose}>
      <div className='flex flex-col justify-between h-full'>
        <p className='text-xl font-bold'>Crear popup</p>
        <TextField
          label='Nombre (para identificarlo)'
          value={name}
          onChange={evt => setName(evt.target.value)}
        />
        <TextField
          label='Texto del botón'
          value={buttonText}
          onChange={evt => setButtonText(evt.target.value)}
        />
        <TextField label='Imagen' value={image} onChange={evt => setImage(evt.target.value)} />
        <TextField label='Url (opcional)' value={url} onChange={evt => setUrl(evt.target.value)} />
        <TextField
          type='number'
          label='Cohort Id (opcional)'
          value={cohortId}
          onChange={evt => setCohortId(evt.target.value)}
        />
        {create ? (
          <div className='flex w-full items-center justify-center'>
            <Create
              body={{ name, button_text: buttonText, image, url, cohort_id: cohortId }}
              handleSuccess={handleSuccess}
              handleError={handleError}
            />
          </div>
        ) : (
          <button
            className={`bg-light_coral text-white rounded-lg p-2 ${
              (!name || !buttonText || !image) && 'bg-gray'
            }`}
            onClick={() => setCreate(true)}
            disabled={!name || !buttonText || !image}
          >
            Crear
          </button>
        )}
      </div>
    </Modal>
  )
}

const Create = ({ body, handleSuccess, handleError }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/broadcast/news-popups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  useEffect(() => {
    if (responseOk && !Array.isArray(data)) {
      handleSuccess(data)
    } else if (!loading && !responseOk) {
      handleError()
    }
  }, [loading, responseOk, data])

  return <LoadingIndicator />
}

const Update = ({ id, active, setUpdate, setActive }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/broadcast/news-popups/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      active: !active
    })
  })

  useEffect(() => {
    if (responseOk) {
      setActive(!active)
      setUpdate(false)
    } else if (!loading) {
      alert('Hubo un error')
      setUpdate(false)
    }
  }, [loading, responseOk])

  return <LoadingIndicator />
}

export default NewsPopup
