import Locations from './Locations'
import { CalendarMonth } from '@mui/icons-material'
import { CardContainer } from './styles'

const Card = ({ lobby, setSelected }) => {
  const occupiedSeats = sumarArray(lobby.companions.map(x => x.seats))

  const Info = ({ time, name }) => {
    const today = new Date((new Date()).getTime() - 3 * 60 * 60 * 1000).toISOString()
    const full = (lobby.seats_available - occupiedSeats == 0)
    const completed = Boolean(lobby.completed)
    const inProgress = (today >= lobby.Journey.departure_time && today <= lobby.Journey.estimated_time)
    const finished = (today >= lobby.Journey.estimated_time)
    const cancelled = lobby.cancelled

    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'end' }} >
        <div style={{ display: 'flex', gap: '1' }} >
          <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {time.substr(time.indexOf('-') + 4, 2) + '/' + time.substr(time.indexOf('-') + 1, 2)}
          </p>
          <CalendarMonth fontSize='medium' style={{ color: '#c65944' }} />
        </div>
        <p>{name}</p>
        {
          cancelled ?
            <div style={{ display: 'flex', gap: '1', color: 'red' }} >
              CANCELADO
            </div>
            :
            <>
              {
                full ?
                  <div style={{ display: 'flex', gap: '1', color: 'orange' }} >
                    LLENO
                  </div>
                  :
                  <></>
              }
              {
                finished ?
                  <div style={{ display: 'flex', gap: '1', color: 'blue' }} >
                    TERMINADO
                  </div>
                  :
                  <></>
              }
              {
                inProgress ?
                  <div style={{ display: 'flex', gap: '1', color: 'green' }} >
                    EN TRANSCURSO
                  </div>
                  :
                  <></>
              }
              {
                completed ?
                  <div style={{ display: 'flex', gap: '1', color: '#1fd655' }} >
                    CONCRETADO
                  </div>
                  :
                  <></>
              }
            </>
        }
      </div>
    )
  }

  return (
    <CardContainer
      onClick={() => {
        setSelected(lobby)
      }}
    >
      <Locations
        originName={lobby.Journey.origin_name.locality}
        destinationName={lobby.Journey.destination_name.locality}
      />
      <Info
        time={lobby.Journey.departure_time}
        name={lobby.Owner_info.first_name}
      />
    </CardContainer>
  )
}

const sumarArray = (arr) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i += 1) {
    sum += arr[i]
  }
  return sum
}

export default Card